<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else class="tabs-container">
		<div class="tabs-wrapper">
			<CTabs>
				<CTab
					v-for="(item, index) in components"
					:key="index"
					:title="item.title"
					:active="item.routerTo === $router.currentRoute.name"
					:to="{ name: item.routerTo }"
				>
					<div class="c-main">
						<div class="container-fluid">
							<component
								:is="item.component"
								:is-loading="list.isLoading"
								:data="selectedSubHeaderType"
								:sub-header-type="subHeaderType"
							/>
						</div>
					</div>
				</CTab>
			</CTabs>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import SubHeaderHomepage from '@/components/SubHeaderHomepage.vue';
import SubHeaderCart from '@/components/SubHeaderCart.vue';
import SubHeaderStaticPage from '@/components/SubHeaderStaticPage.vue';
import { SUB_HEADER_TABS, SUB_HEADER_COMPONENTS, SUB_HEADER_TYPE } from '../enums/subHeader';

export default {
	name: 'SubHeaders',
	components: {
		SubHeaderHomepage,
		SubHeaderCart,
		SubHeaderStaticPage,
	},
	props: {
		subHeaderType: {
			type: String,
			default: SUB_HEADER_TYPE.homepage,
		},
	},
	computed: {
		...mapState('subHeaders', {
			list: 'list',
		}),
		...mapGetters({
			getSubHeaderList: 'subHeaders/getSubHeaderList',
		}),
		components() {
			return SUB_HEADER_TABS.map((tab) => SUB_HEADER_COMPONENTS[tab]);
		},
		subHeadertypes() {
			const widget = {
				homepage: 'homepage',
				cart: 'cart',
				static_page: 'static_page',
			};

			return widget[this.subHeaderType];
		},
		selectedSubHeaderType() {
			return this.getSubHeaderList.find((item) => item.type === this.subHeadertypes);
		},
		isLoading() {
			return this.list.isLoading;
		},
	},
	async created() {
		await this.getSubHeader();
	},
	methods: {
		...mapActions({
			getSubHeader: 'subHeaders/getSubHeader',
		}),
	},
};
</script>
