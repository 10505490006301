<template>
	<form class="main-wrapper mt-4 mb-3" @submit.prevent="handleSubmit">
		<h4 class="mb-4">
			Sub header ribbon
		</h4>
		<CRow>
			<CCol md="6">
				<CInput
					v-model.trim="contentTH"
					label="Content text (TH)"
					type="text"
					data-test-id="input-name"
				/>
			</CCol>
			<CCol md="6">
				<CInput
					v-model.trim="contentEN"
					label="Content text (EN)"
					type="text"
					data-test-id="input-name"
				/>
			</CCol>
			<CCol md="12">
				<CInput
					v-model.trim="$v.link.$model"
					class="margin-bottom-0-important"
					data-id="product-link"
					:is-valid="!$v.link.$error && null"
					:invalid-feedback="$t('global.error.url')"
					label="Link"
				/>
			</CCol>
			<CCol md="12" class="d-flex align-items-center toggle-group">
				<label class="toggle-label" for="homepage-visibility">{{ visibilityTextLabel }}</label>
				<CSwitch
					id="page-visibility"
					:checked="isContenVisible"
					variant="3d"
					size="sm"
					class="switch-custom toggle-visibility"
					color="success"
					@update:checked="handleUpdateVisibility"
				/>
				<span>{{ getVisibilityText(isContenVisible) }}</span>
			</CCol>
			<template v-if="isSubHeaderCart">
				<CCol lg="12" class="mt-3">
					<h4 class="mb-4">
						Banner in Cart
					</h4>
					<label>Desktop banner content (TH)</label>
					<BaseRichTextEditor
						v-model="deskTopBannerContentTH"
						name="deskTopBannerContentTH"
						size="large"
					/>
				</CCol>
				<CCol lg="12" class="mt-3">
					<label>Desktop banner content (EN)</label>
					<BaseRichTextEditor
						v-model="deskTopBannerContentEN"
						name="deskTopBannerContentEN"
						size="large"
					/>
				</CCol>
				<CCol lg="12" class="mt-3">
					<label>Mobile banner content (TH)</label>
					<BaseRichTextEditor
						v-model="mobileBannerContentTH"
						name="mobileBannerContentTH"
						size="large"
					/>
				</CCol>
				<CCol lg="12" class="mt-3">
					<label>Mobile banner content (EN)</label>
					<BaseRichTextEditor
						v-model="mobileBannerContentEN"
						name="mobileBannerContentEN"
						size="large"
					/>
				</CCol>
				<CCol md="12" class="d-flex align-items-center toggle-group">
					<label class="toggle-label" for="homepage-visibility">Banner visibility</label>
					<CSwitch
						id="page-visibility"
						:checked="bannerVisibility"
						variant="3d"
						size="sm"
						class="switch-custom toggle-visibility"
						color="success"
						@update:checked="handleUpdateBannerVisibility"
					/>
					<span>{{ getVisibilityText(bannerVisibility) }}</span>
				</CCol>
			</template>
		</CRow>
		<hr>
		<CRow>
			<CCol
				lg="12"
				class="d-flex justify-content-end align-items-center"
			>
				<CButton
					:disabled="isLoading"
					type="submit"
					color="primary"
				>
					Save
				</CButton>
			</CCol>
		</CRow>
	</form>
</template>

<script>
import { url } from 'vuelidate/lib/validators';
import { VISIBILITY as mappingVisibility } from '../mapping/pages';

export default {
	name: 'FormSubHeaders',

	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: () => {},
		},
		subHeaderType: {
			type: String,
			default: null,
		},
		isEditMode: {
			type: Boolean,
			default: false,
		},
		visibilityTextLabel: {
			type: String,
			default: 'Homepage visibility',
		},
	},

	validations() {
		return {
			link: {
				url,
			},
		};
	},

	data() {
		return {
			contentTH: this.data.contentTH,
			contentEN: this.data.contentEN,
			deskTopBannerContentTH: this.data.deskTopBannerContentTH,
			deskTopBannerContentEN: this.data.deskTopBannerContentEN,
			mobileBannerContentTH: this.data.mobileBannerContentTH,
			mobileBannerContentEN: this.data.mobileBannerContentEN,
			isContenVisible: this.data.isContenVisible,
			bannerVisibility: this.data.isBannerContentVisible,
			type: this.subHeaderType,
			link: this.data.link,
		};
	},

	computed: {
		isSubHeaderCart() {
			return this.subHeaderType === 'cart';
		},
	},

	methods: {
		handleUpdateVisibility(value) {
			this.isContenVisible = value;
		},
		handleUpdateBannerVisibility(value) {
			this.bannerVisibility = value;
		},
		getVisibilityText(value) {
			const text = mappingVisibility[value] || '';
			return this.$t(text);
		},
		handleSubmit() {
			const params = {
				content_th: this.contentTH,
				content_en: this.contentEN,
				is_content_visible: this.isContenVisible,
				banner_content: this.bannerContent,
				desktop: {
					banner_content_th: this.deskTopBannerContentTH,
					banner_content_en: this.deskTopBannerContentEN,
				},
				mobile: {
					banner_content_th: this.mobileBannerContentTH,
					banner_content_en: this.mobileBannerContentEN,
				},
				is_banner_content_visible: this.bannerVisibility,
				type: this.subHeaderType,
				link: this.link,
			};

			this.$emit('onSubmit', params);
		},
	},
};
</script>

<style lang="scss" scoped>
	hr {
		margin: rem(40) 0;
	}

	.level-label {
		margin-bottom: rem(4);
	}

	.toggle-label {
		width: rem(162);
		margin: 0 rem(24) 0 0;
	}

	.toggle-status,
	.toggle-visibility {
		margin-right: rem(12);
	}

	.row-parent-page {
		// margin left, right come from .row
		margin-top: rem(40);
		margin-bottom: rem(-40);
	}

	.toggle-group {
		& + .toggle-group {
			margin-top: rem(32);
		}
	}
</style>