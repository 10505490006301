<template>
	<BaseLoading v-if="isLoading || isEmptyData" />
	<FormSubHeaders
		v-else
		:is-edit-mode="true"
		:data="data"
		:sub-header-type="subHeaderType"
		visibility-text-label="Static page visibility"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import FormSubHeaders from '@/components/FormSubHeaders.vue';
import { scrollToTop } from '../assets/js/helpers';

export default {
	name: 'SubHeaderCart',

	components: {
		FormSubHeaders,
	},

	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: () => {},
		},
		subHeaderType: {
			type: String,
			default: null,
		},
	},

	computed: {
		isEmptyData() {
			return isEmpty(this.data);
		},
	},

	methods: {
		...mapActions({
			updateSubHeader: 'subHeaders/updateSubHeader',
		}),

		async handleSubmit(params = {}) {
			await this.updateSubHeader({
				type: this.subHeaderType,
				params,
			});
			scrollToTop();
		},
	},
};
</script>
